import React, { useEffect, useState } from "react";
import { useAuth } from "../context/auth-context.js";
import {
  getUrlPath,
  getMLOData,
  searchFirebase,
  getUrl,
  getBrand,
  userBrandIsAllowed,
} from "../util/commonFunctions.js";
import DynamicSections from "../components/DynamicSections.js";
import GetInTouchModal from "../components/common/GetInTouchModal.js";
import SideBar from "../components/common/SideBar.js";
import NavBar from "../components/common/NavBar.js";
import ContactSection from "../components/ContactSection.js";
import Footer from "../components/common/Footer.js";
import ErrorPage from "./ErrorPage.js";
import LoadingPage from "./LoadingPage.js";
import ContactMe from "../components/dynamic-sections/ContactMe.js";
import { Helmet } from "react-helmet";

export default function HomePage() {
  const { currentUser, setCurrentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [errorPage, setErrorPage] = useState(false);
  let urlPath = getUrlPath();
  let url = getUrl();

  // GETTING THE MLO INFORMATION, BRAND, AND CUSTOMIZATIONS
  useEffect(() => {
    if (!urlPath) return;
    let mloData = getMLOData(urlPath);
    let details = searchFirebase(urlPath);
    let branding = getBrand(url);

    Promise.all([mloData, details, branding]).then((values) => {
      if (values[0].message || values[2].message) {
        setErrorPage(true);
        setLoading(false);
        return;
      }

      if (values[0].MLO_Page__c === "False") {
        return setErrorPage(true);
      }

      if (!userBrandIsAllowed(values[0], values[1], values[2])) {
        return setErrorPage(true);
      }
      setCurrentUser({
        details: values[0],
        customizations: values[1],
        branding: values[2],
      });
      document.title = `${values[0].FirstName} ${values[0].LastName} | ${values[2].name}`;

      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  }, [urlPath]);

  if (errorPage) return <ErrorPage />;
  if (loading) return <LoadingPage />;
  if (!currentUser) return <ErrorPage />;

  console.log(currentUser);

  // replace www. with nothing

  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href={currentUser.branding.favicon} />
      </Helmet>
      <div className="h-full flex flex-col md:flex-row">
        <div className="hidden md:block h-screen z-50 w-1/5 max-w-[450px] min-w-[250px] fixed">
          {/* This is the medium to large screen layout */}
          <SideBar currentUser={currentUser} />
        </div>
        <div className="md:hidden block ">
          {/* This is the small screen layout */}
          <SideBar currentUser={currentUser} />
        </div>
        <div className="w-full md:w-4/5 ml-auto">
          <NavBar currentUser={currentUser} />
          {currentUser?.showGetInTouchModal && (
            <GetInTouchModal currentUser={currentUser} />
          )}
          {!currentUser?.customizations ? (
            <ContactMe currentUser={currentUser} />
          ) : (
            <div className="">
              <div className="md:h-14"></div>
            </div>
          )}
          <DynamicSections currentUser={currentUser} />
          <div className="px-12 md:px-24">
            <ContactSection currentUser={currentUser} />
          </div>
          <Footer currentUser={currentUser} />
        </div>
      </div>
    </>
  );
}
